import React, { useState } from "react"
import axios from 'axios';

import SEO from "../components/SEO/Seo"
import Illustrations from '../components/Illustrations/Illustrations'

export default ({ location }) => {

	const [ identifier, setIdentifier ] = useState('')
	const [ showReset, setShowReset ] = useState( true )

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		await axios
		.post( '/api/auth/forgot-password', {
			email: identifier,
		})
		.then(response => {
			// Handle success.
			setShowReset( false )
		})
		.catch(error => {
			//should we throw an error if the user doesnt exist?
			// Handle error.
			console.log('An error occurred:', error.response);
		});
	}

	return (
		<>
			<SEO title="Forgotten Password" description="Forgotten password to account on dream on." pathname={ location.pathname } />

			<div className="wrapper">
				<div className="form-page">
					<div className="form-page__header">
						<h1 className="form-page__heading">Forgotten password</h1>
						<p className="form-page__info">
							Type in your email address below and we'll send you an email with a link to create a new password.
						</p>
					</div>
					{ showReset ? (
						<form className="form" onSubmit={ handleSubmit }>
							<div className="form__input-container">
								<label className="form__label" htmlFor="email">
									Email
								</label>
								<input
									onChange={ e => { setIdentifier(e.target.value)	}}
									value={ identifier }
									className="form__input" id="email" type="email" placeholder="Enter your email address" name="email" required/>
							</div>
							<div className="form__button-container">
								<button className="form__submit button button--primary button--full-width" type="submit">Reset Password</button>
							</div>
						</form>
					) : (
						<p className="form-page__success">
							Reset Password Link has been sent. We've sent a reset password email to { identifier }
							<Illustrations class="form-page__success-image" illustration={ 'Family' } />
						</p>
					) }
				</div>
			</div>
		</>
	)
}